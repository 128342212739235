<template>
  <div
    class="c-child-product"
    :class="[full ? 'c-child-product--full' : '', imgPosition === 'right' ? 'c-child-product--img-right' : '']"
  >
    <div v-if="product">
      <div class="c-child-product__img-container h-80 md:h-60 lg:h-96">
        <v-slider
          v-if="product.media"
          :images="product.media"
          class="h-full"
        />
        <div
          v-if="product.size"
          class="c-child-product__size"
        >{{ product.size }} cm</div>
      </div>

      <div class="c-child-product__info-container">
        <div class="c-child-product__info">
          <h3
            class="c-child-product__title"
            v-if="product.title"
          >
            {{ product.title }} - {{ product.sku }}
          </h3>

          <div
            class="c-child-product__description"
            v-if="product.text"
            v-html="getOriginalTextWithoutLabel(product.text)"
          ></div>

          <span
            class="c-child-product__energy-label"
            v-if="getEnergyLabelIcon(product.text)"
            data-html2canvas-ignore
          >
            <img
              :src="require('@/assets/images/labels/' + getEnergyLabelIcon(product.text))"
              alt=""
              :crossorigin="$store.state.env !== 'production' ? null : '*'"
            >
          </span>

          <v-link
            v-if="product.sku"
            class="c-child-product__instructions"
            :url="`https://media3.bsh-group.com/Documents/specsheet/${pdfLocale}/${product.sku}.pdf`"
            :text="translations[locale].text.download_detail_product_pdf"
            :newTab="true"
            icon="download"
            iconSize="16"
            classes="text-black"
            iconClasses="text-black"
          />

          <div
            class="c-step__button-wrapper"
            v-if="addToKitchenButton"
          >
            <v-button
              textClasses="text-black"
              variation="primary"
              :text="translations[locale].text.add_to_my_kitchen"
              @click="saveToKitchen(product.id, product.size); addProduct([product]);"
            ></v-button>
            <!-- <div class="c-child-product__addedToCartLink" v-if="productAddedToKitchenCheck(this.stepInfo.categories[0].slug ,product.id)">
                <v-link text="Continue to next step" href="" @click="$router.push({ path: `/${locale}${stepInfo.link_url}` })" />
            </div> -->
            <!-- <v-button variation="primary" :addedToKitchen="addedProducts.includes(product.id) ? true : false" :text="translations[locale].text.add_to_my_kitchen" href="" @click="saveToKitchen(product.id, product.size); addProduct([product]);" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link/Link.vue'
import Slider from '@/components/ImageSlider/ImageSlider.vue'
import Button from '@/components/Button/Button.vue'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

import { getEnergyLabelIcon, getOriginalTextWithoutLabel } from '@/labels'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'Child Product',
  props: {
    id: Number,
    title: String,
    description: String,
    size: Number,
    full: String,
    imgPosition: String,
    sku: String,
    addToKitchenButton: String,
    product: Object,
    validator: (imgPosition) => ['left', 'right'].includes(imgPosition)
  },
  components: {
    'v-link': Link,
    'v-slider': Slider,
    'v-button': Button
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      slug: this.$route.params.slug,
      translations
    }
  },
  mounted () {
    // this.loadProduct(this.id)
  },
  computed: {
    ...mapGetters([
      'stepInfo',
      'categoryinfo',
      'apiUrl',
      'apiHeader',
      'token',
      'stepHistory',
      'chosenFeatures',
      'addedProduct',
      'addedProducts',
      'kitchenId'
    ]),
    pdfLocale () {
      switch (this.locale) {
        case 'se':
          return 'sv-SE'
        case 'no':
          return 'no-NO'
        case 'dk':
          return 'da-DK'
        case 'fi':
          return 'fi-FI'
        default:
          return 'en-BSH'
      }
    }
  },
  methods: {
    ...mapActions(['loadStartPage', 'loadStep', 'loadCategory']),
    ...mapMutations([
      'updateHistory',
      'createHistoryObject',
      'removeFromHistory',
      'addProduct',
      'choices',
      'updateChoices',
      'updateKitchen',
      'clearChoices',
      'updateAddedProducts',
      'createProductObject',
      'categoryDone'
    ]),
    // loadProduct (id) {
    //   axios.get(`${this.apiUrl}/api/product/${id}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${this.token}`,
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   ).then((response) => {
    //     this.product = response.data.data
    //   })
    // },
    async saveToKitchen (productId) {
      this.createProductObject()

      const payload = {
        id: productId,
        slug: this.slug
      }

      this.updateAddedProducts(payload)
      const productIds = Object.values(this.addedProducts).flat(1)

      await axios.post(`${this.apiUrl}/api/kitchen/products`,
        {
          ...(this.kitchenId ? { id: this.kitchenId } : {}),
          product_ids: productIds,
          lang: this.locale
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        this.updateKitchen(response.data)
        this.categoryDone({
          catId: this.categoryinfo.id,
          parentCatId: this.categoryinfo.parent_id
        })
        this.$router.push({ path: `/${this.locale}${this.stepInfo.link_url}` })
      })
    },
    productAddedToKitchenCheck (slug, id) {
      if (this.addedProducts[slug]) {
        if (this.addedProducts[slug].filter(e => e.id === id).length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getEnergyLabelIcon,
    getOriginalTextWithoutLabel
  }
}
</script>

<style lang="scss" scoped>
.c-child-product {
  background-color: #fff;
  position: relative;
  max-width: calc(50% - 40px);

  &__energy-label {
    display: block;

    img {
      max-width: 80px;
      margin-left: -7px;
    }

    width: 100%;
  }

  &__addedToCartLink {
    margin-top: 16px;
  }

  &--full {
    display: flex;
    width: 100%;

    .c-child-product__size {
      top: 32px;
      bottom: auto;
    }

    .c-child-product__img-container,
    .c-child-product__info-container {
      flex: 1;
      width: 50%;
    }
  }

  &__img-container {
    position: relative;
    min-height: initial;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__size {
    @include s2;
    background-color: $orange;
    display: inline-block;
    padding: 9px 16px 7px;
    font-weight: 700;
    position: absolute;
    left: 0;
    bottom: 16px;
  }

  &__energy-label {
    max-width: 100px;
    display: block;
    margin-bottom: 10px;
  }

  &__info {
    padding: 40px;
  }

  &__title {
    @include t4;

    color: $deep-blue-2;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 16px;
    color: $deep-blue-2;

    &:deep(p),
    &:deep(ul) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &:deep(li) {
      color: $deep-blue-2;
      font-size: 1rem;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%2341AAAA'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__feature-text {
    @include t6;
    color: $stone-dark;
  }

  &__instructions {
    line-height: 20px;
  }

  .c-step__button-wrapper {
    margin-top: 30px;
  }

  @include mq($screen-xl, min) {
    &__size {
      bottom: 120px;
    }
  }

  @include mq($screen-m, max) {
    &--full {
      flex-direction: column;

      .c-child-product__size {
        bottom: 16px;
        top: auto;
      }
    }

    &__info {
      padding: 20px;
    }
  }

  @include mq($screen-m, min) {
    &--full {
      &:not(.c-child-product--img-right) {
        .c-child-product__size {
          right: 0;
          left: auto;
        }
      }
    }

    &--img-right {
      .c-child-product__img-container {
        order: 2;
      }
    }
  }
}
</style>
