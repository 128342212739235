<template>
  <div
    class="c-textmedia"
    :class="imgPosition === 'right' ? 'c-textmedia--img-right' : ''"
  >
    <div
      class="c-textmedia__img-container"
      :class="imgSize === 'big' ? 'c-textmedia__img-container--big' :''"
    >
      <v-slider :images="images"/>
    </div>

    <div class="c-textmedia__text-container">
      <div class="c-textmedia__text">
        <h2 class="c-textmedia__heading">
          {{ heading }}
        </h2>

        <div class="c-textmedia__content" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/components/ImageSlider/ImageSlider.vue'

export default {
  name: 'TextMedia',
  props: {
    heading: String,
    content: String,
    images: Array,
    imgPosition: String,
    imgSize: String,
    number: Number,
    validator: (imgPosition) => ['left', 'right'].includes(imgPosition)
  },
  components: {
    'v-slider': Slider
  },
  data () {
    return {
      locale: this.$route.params.lang
    }
  },
  methods: {},
  computed: {}
}

</script>

<style lang="scss" scoped>
.c-textmedia {
  display: flex;
  margin-bottom: 16px;

  .c-textmedia--img-right {
    .c-textmedia__img-container {
      order: 2;
    }

    .c-textmedia__text-container {
      order: 1;
    }
  }

  .c-textmedia__img-container {
    display: flex;
    flex: 2;
    max-width: 50%;

    @include mq($screen-m, max) {
      max-width: 100%;
    }
  }

  .c-textmedia__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .c-textmedia__text-container {
    flex: 1;
  }

  .c-textmedia__text {
    padding: 0 40px;
    color: #fff;

    &:deep(p), &:deep(ul) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &:deep(li) {
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%2341AAAA'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  .c-textmedia__heading {
    @include t4;

    font-size: 1.675rem;
    line-height: 2.1rem;
    margin-bottom: 16px;
  }

  .c-textmedia__content {
    //
  }

  @include mq($screen-l, max) {
    .c-textmedia__img-container {
      flex: 1;
    }
  }

  @include mq($screen-m, max) {
    flex-direction: column;

    .c-textmedia__text {
      padding: 20px;
    }
  }
}

.c-bundle__textmedia {
  margin-bottom: 40px;
}
</style>
