export default function setPageMeta (title, text) {
  if (title) {
    document.title = title + ' - Siemens studioLine Guide'
  } else {
    document.title = 'Siemens studioLine Guide'
  }

  const textWithoutHTML = text?.replace(/<\/?[^>]+(>|$)/g, '')

  if (textWithoutHTML) {
    let meta = document.querySelector('meta[name="description"]')

    if (!meta) {
      meta = document.createElement('meta')
      meta.name = 'description'
      document.head.appendChild(meta)
    }

    meta.content = textWithoutHTML
  } else {
    const meta = document.querySelector('meta[name="description"]')
    if (meta) {
      meta.remove()
    }
  }
}
