<template>
  <div
    class="text-white"
    v-if="pageInfo.data"
  >
    <div class="relative min-h-screen">
      <div class="lg:absolute left-0 top-0 lg:w-1/2 lg:h-full">
        <div
          class="hidden lg:block absolute w-full h-full bg-gradient-to-l from-black to-transparent opacity-50"
        ></div>
        <img
          class="object-cover object-left w-full h-full"
          :src="apiUrl + '/uploads/' + pageInfo.data.image"
          :crossorigin="$store.state.env !== 'production' ? null : '*'"
        >
      </div>

      <div
        class="lg:w-3/5 lg:ml-auto py-8 lg:py-12 px-5 lg:pl-0 lg:pr-20 lg:min-h-screen flex flex-col"
      >
        <div
          class="absolute top-5 right-5 lg:top-0 lg:right-0 lg:relative flex justify-end justify-self-start"
        >
          <v-language-switcher />
        </div>

        <div class="relative lg:mt-16">
          <h1
            class="text-4.5xl lg:text-7xl mb-6 font-heading"
            v-html="pageInfo.data.title"
          ></h1>
          <div v-html="pageInfo.data.text"></div>
          <div class="mt-8 flex flex-wrap justify-between gap-6 items-center">
            <div class="flex flex-wrap gap-6 items-center">
              <router-link :to="{ path: `/${locale}/category${pageInfo.data.link_url}` }">
                <v-button
                  variation="primary"
                  :text="pageInfo.data.link_text"
                />
              </router-link>

              <div>
                <p>{{ translations[locale].text.dont_have_time_right_now }}</p>
                <v-link
                  id="show-modal"
                  :text="translations[locale].text.remind_me_later"
                  url=""
                  icon="arrow-right"
                  icon-hover="arrow-right-longer"
                  iconSize="12"
                  iconSizeHover="16"
                  @click.prevent="showModal = true"
                />
              </div>
            </div>

            <a
              :href="'/studioLine_Concept_' + locale + '.pdf'"
              target="_blank"
            >
              <v-tooltip
                :text="translations[locale].text.info_about_concept"
                position="left"
              >
                <v-icon
                  class="text-white"
                  type="info"
                  size="24"
                />
                <span class="ml-2 md:hidden">{{ translations[locale].text.info_about_concept
                  }}</span>
              </v-tooltip>
            </a>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <v-modal
        v-if="showModal"
        @close="showModal = false"
      ></v-modal>
    </transition>

    <transition name="fade">
      <div
        class="lds-roller-wrapper"
        v-if="loading"
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
  </div>

  <v-cookie-popup />

</template>
<script>
import CookiePopup from '@/components/CookiePopup.vue'
import Button from '@/components/Button/Button.vue'
import Link from '@/components/Link/Link.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher.vue'
import { mapGetters, mapActions } from 'vuex'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import Modal from '@/components/Modal/Modal.vue'
import Icon from '@/components/Icon/Icon.vue'
import Tooltip from '@/components/Tooltip.vue'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  Name: 'Start',
  async created () {
    await this.loadStartPage()

    if (this.categorieslist && this.categorieslist.length === 0) {
      await this.loadCategoriesList()
    }

    const curLang = document.querySelector('.c-language-switcher__button')?.dataset?.lang

    if (curLang !== 'en') {
      await this.loadStartPageDifLang()
    }

    this.getRedirectUri()
    this.loading = false
  },
  data () {
    return {
      locale: this.$route.params.lang,
      translations,
      showModal: false,
      loading: true
    }
  },
  components: {
    'v-cookie-popup': CookiePopup,
    'v-button': Button,
    'v-link': Link,
    'v-language-switcher': LanguageSwitcher,
    'v-modal': Modal,
    'v-icon': Icon,
    'v-tooltip': Tooltip
  },
  mounted () {
    // const lastVisit = this.$store.state.lastVisit
    // const now = new Date().getTime()

    // // 1 day
    // if (now - lastVisit >= 24 * 60 * 60 * 1000) {
    //   console.debug('Clearing storage...')
    //   localStorage.removeItem('vuex')
    //   window.location.href = `/${this.$route.params.lang}/`
    // }
  },
  computed: {
    ...mapGetters(['language', 'pageInfo', 'categoriesList', 'apiUrl'])
  },
  methods: {
    ...mapActions(['loadStartPage', 'loadCategoriesList', 'loadStartPageDifLang', 'updateReturnUrl']),
    getRedirectUri: function () {
      this.updateReturnUrl(this.$route.query.returnUrl)
    }
  }

}

</script>

<style
  lang="scss"
  scoped
></style>
