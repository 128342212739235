<template>
  <div class="flex items-center justify-between">
    <div class="mr-4">
      <v-link
        id="show-modal"
        :text="translations[locale].text.back"
        url=""
        icon="arrow-left"
        iconSize="12"
        iconLeft
        classes="text-brand-vibrant-petrol"
        @click.prevent="$router.go(-1)"
      />
    </div>

    <div class="">
      <v-button variation="primary" :text="translations[locale].text.skip" @click="skipStep"/>
    </div>
  </div>
</template>

<script>
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import Button from '@/components/Button/Button.vue'
import Link from '@/components/Link/Link.vue'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  name: 'StepButtons',
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  },
  methods: {
    skipStep () {
      const activeElement = document.querySelector('.router-link-active.c-progressbar__link')
      const routeHref = activeElement.parentElement.nextElementSibling.getElementsByTagName('a')[0].getAttribute('href')
      this.$router.push(routeHref)
    }
  },
  components: {
    'v-button': Button,
    'v-link': Link
  }
}

</script>
