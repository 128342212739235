<template>
  <div v-if="kitchenInfo?.data.item_count > 0">
    <router-link class="flex items-center font-bold text-brand-deep-blue-4"
                 :to="{ path: `/${locale}/my-kitchen` }">
      <v-icon placement="before" type="kitchen" size="24"/>
      <span>{{ kitchenInfo.data.item_count }}</span>
      &nbsp;{{ translations[locale].text.itemscount }}
    </router-link>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon.vue'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import { mapGetters } from 'vuex'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'ItemsCount',
  data () {
    return {
      locale: this.$route.params.lang,
      translations
    }
  },
  computed: {
    ...mapGetters([
      'kitchenInfo'
    ])
  },
  components: {
    'v-icon': Icon
  }
}
</script>
