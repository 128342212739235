<template>
  <div
    class="c-product"
    :class="[full ? 'c-product--full' : '', imgPosition === 'right' ? 'c-product--img-right' : '']"
  >
    <div class="c-product__img-container">
      <v-slider
        v-if="images.length > 1"
        :images="images"
        class="h-full"
      />

      <img
        v-else
        class="c-product__img"
        :src="images[0] ? apiUrl + '/uploads/' + images[0] : require('@/assets/images/placeholder.jpg')"
        :crossorigin="$store.state.env !== 'production' ? null : '*'"
      />

      <div
        v-if="size"
        class="c-product__size"
      >{{ size }} cm</div>
    </div>

    <div class="c-product__info-container">
      <div class="c-product__info">
        <h3 class="c-product__title">
          {{ title }} - {{ sku }}
        </h3>

        <div
          class="c-product__description"
          v-html="getOriginalTextWithoutLabel(description)"
        ></div>

        <span
          v-if="getEnergyLabelIcon(description)"
          data-html2canvas-ignore
          class="c-product__energy-label"
        >
          <img
            :src="require('@/assets/images/labels/' + getEnergyLabelIcon(description))"
            alt=""
            :crossorigin="$store.state.env !== 'production' ? null : '*'"
          >
        </span>

        <v-link
          v-if="sku && sku"
          class="c-product__instructions"
          :url="`https://media3.bsh-group.com/Documents/specsheet/${pdfLocale}/${sku}.pdf`"
          :text="translations[locale].text.download_detail_product_pdf"
          :newTab="true"
          icon="download"
          iconSize="12"
          classes="text-black"
          iconClasses="text-black"
        />

        <div
          v-if="addToKitchenButton"
          class="c-step__button-wrapper"
        >
          <v-button
            variation="primary"
            :text="translations[locale].text.add_to_my_kitchen"
            textClasses="text-black"
            href=""
            @click="saveToKitchen(product.id, product.size); addProduct([product]);"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link/Link.vue'
import Slider from '@/components/ImageSlider/ImageSlider.vue'
import Button from '@/components/Button/Button.vue'
import axios from 'axios'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import { getEnergyLabelIcon, getOriginalTextWithoutLabel } from '@/labels'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  name: 'Product',
  props: {
    images: Array,
    title: String,
    description: String,
    size: Number,
    full: String,
    imgPosition: String,
    sku: String,
    addToKitchenButton: String,
    product: Object,
    validator: (imgPosition) => ['left', 'right'].includes(imgPosition)
  },
  components: {
    'v-link': Link,
    'v-slider': Slider,
    'v-button': Button
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      translations
    }
  },
  computed: {
    ...mapGetters([
      'stepInfo',
      'categoryinfo',
      'apiUrl',
      'apiHeader',
      'token',
      'stepHistory',
      'chosenFeatures',
      'addedProduct',
      'addedProducts',
      'kitchenId',
      'categorieslist'
    ]),
    pdfLocale () {
      switch (this.locale) {
        case 'se':
          return 'sv-SE'
        case 'no':
          return 'no-NO'
        case 'dk':
          return 'da-DK'
        case 'fi':
          return 'fi-FI'
        default:
          return 'en-BSH'
      }
    }
  },
  methods: {
    ...mapActions(['loadStartPage']),
    ...mapMutations([
      'updateHistory',
      'createHistoryObject',
      'removeFromHistory',
      'addProduct',
      'choices',
      'updateChoices',
      'updateKitchen',
      'clearChoices',
      'updateAddedProducts',
      'createProductObject',
      'clearAddedProduct',
      'updateStepCounter',
      'clearAddedProductsInCategory',
      'categoryDone'
    ]),
    getEnergyLabelIcon,
    getOriginalTextWithoutLabel,
    saveToKitchen: async function (productId, productSize) {
      this.createProductObject()
      let parentCategorySlug
      if (this.categoryinfo.parent_id !== 0) {
        parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.parent_id)
      } else {
        parentCategorySlug = this.categorieslist.find(category => category.id === this.categoryinfo.id)
      }
      const payload = {
        id: productId,
        size: productSize,
        slug: this.categoryinfo.slug,
        parentSlug: parentCategorySlug ? parentCategorySlug.slug : null,
        bundleproduct: 0
      }
      this.updateAddedProducts(payload)
      const productIds = Object.values(this.addedProducts).flat(1)
      const onlyProductIds = productIds.map(a => a.id)
      await axios.post(`${this.apiUrl}/api/kitchen/products`,
        {
          ...(this.kitchenId ? { id: this.kitchenId } : {}),
          product_ids: onlyProductIds,
          lang: this.locale
        },
        this.apiHeader
      ).then((response) => {
        this.updateKitchen(response.data)
        this.categoryDone({
          catId: this.categoryinfo.id,
          parentCatId: this.categoryinfo.parent_id
        })
        this.$router.push({ path: `/${this.locale}${this.stepInfo.link_url}` })
      }).catch(function (error) {
        if (error.response) {
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
    }
  }

}
</script>

<style
  lang="scss"
  scoped
>
.c-product {
  background-color: $snow;
  position: relative;

  &__energy-label {
    display: block;

    img {
      max-width: 80px;
      margin-left: -7px;
    }

    width: 100%;
  }

  &--full {
    display: flex;
    width: 100%;

    .c-product__size {
      top: 32px;
      bottom: auto;
      padding: 9px 16px 7px;
    }

    .c-product__img-container,
    .c-product__info-container {
      flex: 1;
      width: 50%;
      display: flex;
      align-items: center;
    }
  }

  &__img-container {
    position: relative;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__size {
    @include s2;

    background-color: $orange;
    display: inline-block;
    padding: 3px 8px;
    font-weight: 700;
    position: absolute;
    left: 0;
    bottom: 1rem;
  }

  &__info {
    padding: 40px;
  }

  &__title {
    @include t5;

    margin-bottom: 1rem;
    color: $deep-blue-2;
  }

  &__description {
    margin-bottom: 1rem;
    color: $deep-blue-2;

    &:deep(p),
    &:deep(ul) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &:deep(li) {
      color: $deep-blue-2;
      font-size: 1rem;
      padding-left: 32px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::before {
        content: "";
        height: 1rem;
        width: 1rem;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3438 0.96875L16 2.625L5.59375 13.0312L0 7.4375L1.65625 5.78125L5.25 8.875L14.3438 0.96875Z' fill='%233CC8BE'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }

  &__feature-text {
    @include t6;
    color: $stone-dark;
  }

  &__instructions {
    @apply mt-4;
    line-height: 20px;
  }

  .c-step__button-wrapper {
    margin-top: 30px;
  }

  @include mq($screen-xl, min) {
    &__size {
      bottom: 120px;
    }
  }

  @include mq($screen-m, max) {
    &--full {
      flex-direction: column;

      .c-product__size {
        bottom: 1rem;
        top: auto;
      }

      .c-product__img-container,
      .c-product__info-container {
        flex: 1;
        width: 100%;
      }
    }

    &__info {
      padding: 20px;
    }
  }

  @include mq($screen-m, min) {
    &--full {
      &:not(.c-product--img-right) {
        .c-product__size {
          right: 0;
          left: auto;
        }
      }
    }

    &--img-right {
      .c-product__img-container {
        order: 2;
      }
    }
  }
}
</style>
