<template>
  <header class="c-header u-flex">
    <v-cookie-popup/>
    <div class="c-header__inner l-container">
      <router-link :to="{ path: `/${locale}/` }">
        <img
          class="c-header__logo"
          src='@/assets/images/siemens_logo.svg'
          :crossorigin="$store.state.env !== 'production' ? null : '*'"
        />
      </router-link>

      <div class="c-header__right" data-html2canvas-ignore>
        <v-language-switcher/>

        <div class="flex flex-wrap justify-end items-center mt-5">
          <a :href="'/studioLine_Concept_' + locale + '.pdf'" target="_blank" class="">
            <v-tooltip :text="translations[locale].text.info_about_concept" position="left">
              <v-icon class="text-brand-deep-blue-4" type="info" size="24"/>
              <span class="ml-2 md:hidden text-brand-deep-blue-4">{{
                  translations[locale].text.info_about_concept
                }}</span>
            </v-tooltip>
          </a>

          <v-itemscount class="my-2 ml-7"/>
        </div>
      </div>
    </div>
  </header>

  <div class="l-container no-print" data-html2canvas-ignore>
    <v-progressbar/>
  </div>
</template>

<script>
import CookiePopup from '@/components/CookiePopup.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher.vue'
import ItemsCount from '@/components/ItemsCount/ItemsCount.vue'
import Icon from '@/components/Icon/Icon.vue'
import Tooltip from '@/components/Tooltip.vue'

import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'Header',
  data () {
    return {
      dropdownOpen: false,
      mobile: window.innerWidth <= 750,
      spritePath: require('@/assets/images/sprite.svg'),
      locale: this.$route.params.lang,
      translations
    }
  },
  created () {
    addEventListener('resize', () => {
      this.mobile = innerWidth <= 750
    })
  },
  props: {
    homepage: Boolean
  },
  components: {
    'v-cookie-popup': CookiePopup,
    'v-language-switcher': LanguageSwitcher,
    'v-progressbar': ProgressBar,
    'v-itemscount': ItemsCount,
    'v-icon': Icon,
    'v-tooltip': Tooltip
  }
}

</script>

<style lang="scss" scoped>
.c-header {
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  justify-content: space-between;

  @include mq($screen-m, max) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    @include mq($screen-s, max) {
      justify-content: center;
    }
  }

  &__language-button {
    @include s2;
    color: $stone-8;
    background-color: transparent;
    position: relative;
    transition: $default-transition;

    &:hover, &.is-open {
      color: $teal-light;
    }
  }

  &__language-switcher {
    position: relative;
  }

  &__languages {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    width: 150px;
    background-color: $snow;
  }

  &__language {
    transition: $default-transition;
    display: block;
    padding: 10px;
    border-bottom: 1px solid $stone-18;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: $stone-1;

    &:hover {
      color: $teal-light;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  @include mq($screen-m, max) {
    &__logo {
      width: 170px;
    }
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
</style>
