import { createRouter, createWebHistory } from 'vue-router'
// import App from '@/App/App.vue'
import Home from '@/views/Home/Home.vue'
import store from '@/store/index.js'
import Start from '@/views/Start/Start.vue'
import MyKitchen from '@/views/MyKitchen/MyKitchen.vue'
import CategoryStart from '@/views/Category/CategoryStart/CategoryStart.vue'
import Step from '@/views/Category/Step/Step.vue'
import Category from '@/views/Category/Category.vue'

import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

import setPageMeta from '@/meta'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

const routes = [
  {
    path: '/:lang?/',
    name: 'Home',
    components: {
      default: Home
    },
    children: [
      {
        path: '',
        name: 'Start Page',
        component: Start
      },
      {
        path: '/:lang?/my-kitchen/',
        name: 'My Kitchen',
        component: MyKitchen,
        children: [
          {
            path: '',
            name: 'My Kitchen New',
            component: MyKitchen
          },
          {
            path: '/:lang?/my-kitchen/:kitchenSlug?/',
            name: 'My Kitchen Slug',
            component: MyKitchen
          }
        ]
      },
      {
        path: '/:lang?/category/:slug?/',
        name: 'Category',
        component: Category,
        children: [
          {
            path: '/:lang?/category/:slug?/',
            name: 'Category Start',
            component: CategoryStart
          },
          {
            path: '/:lang?/category/:slug?/:stepId?/',
            name: 'Step',
            component: Step
          }
        ]
      }
    ],
    beforeEnter: (to, from, next) => {
      const returnUrl = to?.query?.returnUrl

      if (returnUrl) {
        localStorage.setItem('returnUrl', returnUrl)
      }

      if (to.params.lang) {
        localStorage.setItem('locale', to.params.lang)

        const lastVisit = store.state.lastVisit
        const now = new Date().getTime()

        if (now - lastVisit >= 24 * 60 * 60 * 1000 || ((to.name === 'Home' || to.name === 'Start Page') && now - lastVisit >= 2000)) {
          console.debug('Clearing storage...')
          localStorage.removeItem('vuex')
          window.location.href = `/${to.params.lang}/`
          return
        }

        next()
      } else {
        if (!localStorage.getItem('locale')) {
          localStorage.setItem('locale', 'en')
        }

        next({ path: `/${localStorage.getItem('locale')}${to.path}` })
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.lang = to.params.lang
      this.setCode()
      next()
    }
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll 10px above the element #main
    return {
      // could also be
      // el: document.getElementById('main'),
      el: '#mainNavProgressBar',
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || 'en'
  let pageTitle = to.meta.title
  let pageDescription = to.meta.description

  if (to.name === 'Start Page') {
    pageDescription = translations[lang].text.meta_description_start
  } else if (to.name === 'My Kitchen New') {
    pageTitle = translations[lang].text.my_kitchen
    pageDescription = translations[lang].text.meta_description_my_kitchen
  }

  setPageMeta(pageTitle, pageDescription)

  next()
})

export default router
