export function getEnergyLabelIcon (stepText) {
  if (stepText) {
    const strippedString = stepText.replace(/(<([^>]+)>)/gi, '')

    if (strippedString.includes('A (A-G)')) {
      return 'a(a-g).svg'
    } else if (strippedString.includes('B (A-G)')) {
      return 'b(a-g).svg'
    } else if (strippedString.includes('C (A-G)')) {
      return 'c(a-g).svg'
    } else if (strippedString.includes('D (A-G)')) {
      return 'd(a-g).svg'
    } else if (strippedString.includes('E (A-G)')) {
      return 'e(a-g).svg'
    } else if (strippedString.includes('F (A-G)')) {
      return 'f(a-g).svg'
    } else if (strippedString.includes('G (A-G)')) {
      return 'g(a-g).svg'
    } else if (strippedString.includes('A (A++-E)')) {
      return 'a(a-e).png'
    } else if (strippedString.includes('A+ (A++-E)')) {
      return 'a+(a-e).png'
    } else if (strippedString.includes('A++ (A++-E)')) {
      return 'a++(a-e).png'
    } else if (strippedString.includes('A+++ (A++-E)')) {
      return 'a+++(a-e).png'
    } else if (strippedString.includes('B (A++-E)')) {
      return 'b(a-e).png'
    } else if (strippedString.includes('C (A++-E)')) {
      return 'c(a-e).png'
    } else if (strippedString.includes('D (A++-E)')) {
      return 'd(a-e).png'
    } else {
      return false
    }
  } else {
    return false
  }
}

export function getOriginalTextWithoutLabel (stepText) {
  if (stepText) {
    const labels = [
      'A \\(A-G\\)', 'B \\(A-G\\)', 'C \\(A-G\\)', 'D \\(A-G\\)', 'E \\(A-G\\)', 'F \\(A-G\\)', 'G \\(A-G\\)',
      'A \\(A\\+\\+\\-E\\)', 'A\\+ \\(A\\+\\+\\-E\\)', 'A\\+\\+ \\(A\\+\\+\\-E\\)', 'A\\+\\+\\+ \\(A\\+\\+\\-E\\)', 'B \\(A\\+\\+\\-E\\)', 'C \\(A\\+\\+\\-E\\)', 'D \\(A\\+\\+\\-E\\)'
    ]

    labels.forEach(label => {
      const regexLi = new RegExp('<li>[^<]*?' + label + '[^<]*?</li>', 'g')
      stepText = stepText.replace(regexLi, '')

      const regex = new RegExp(label, 'g')
      stepText = stepText.replace(regex, '')
    })

    return stepText
  } else {
    return false
  }
}
