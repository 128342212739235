<template>
  <div class="imageslider">
    <splide :options="options" :extensions="extensions" @splide:moved="move($event)">
      <template v-for="(media, i) in mediaData" :key="media">
        <splide-slide
          v-if="(media.fileExtension !== 'mp4' || !media.fileExtension !== 'mov') || (cookieConsent && (media.fileExtension === 'mp4' || media.fileExtension === 'mov'))"
          :class="(media.fileExtension === 'mp4' || media.fileExtension === 'mov') ? 'htmlvideo' : ''"
          ref="splide"
        >
          <!-- <img v-if="(media.fileExtension !== 'mp4' || !media.fileExtension !== 'mov')" :data-splide-lazy="apiUrl + '/uploads/' + media.fileName" :src="apiUrl + '/uploads/' + media.fileName" :key="media"> -->
          <div v-if="(media.fileExtension === 'mp4' || !media.fileExtension === 'mov')"
               class="relative w-full h-full">
            <video
              :ref="'htmlVideo' + i"
              controls
              muted
              class="absolute inset-0 w-full h-full object-cover"
            >
              <source
                :src="(media.fileExtension === 'mp4' || media.fileExtension === 'mov') ?  apiUrl + '/uploads/' + media.fileName : ''"
                type="video/mp4"
              >
            </video>
          </div>

          <img
            v-if="(media.fileExtension !== 'mp4' && !media.fileExtension !== 'mov')"
            :data-splide-lazy="apiUrl + '/uploads/' + media.fileName" :src="apiUrl + '/uploads/' + media.fileName"
            :crossorigin="$store.state.env !== 'production' ? null : '*'"
          >
          <!-- <img v-else :src="apiUrl + '/uploads/' + image"> -->
        </splide-slide>
      </template>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/splide-core.min.css'
// import Video from '@splidejs/splide-extension-video'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Slider',
  props: {
    images: Array,
    height: String,
    mediaHeight: Number,
    mediaHeightMobile: Number
  },
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      foundVideos: [],
      mediaData: {},
      options: {
        perPage: 1,
        start: 0,
        rewind: true,
        pagination: false,
        updateOnMove: true,
        arrows: Boolean(this.images.length > 1),
        preloadPages: 2,
        speed: 300,
        /* lazyLoad: true, */
        /* height: this.mediaHeight ? this.mediaHeight : 420, */
        /* autoHeight: true */
        video: {
          autoplay: true,
          mute: true,
          preload: true
        },
        breakpoints: {
          900: {
            width: '100%'
            /* height: this.mediaHeightMobile ? this.mediaHeightMobile : 300 */
          }
          /* 600: {
            height: this.mediaHeightMobile ? this.mediaHeightMobile + this.mediaHeightMobile : 300
          } */
        }
      },
      // Register the extension.
      extensions: {
        // Video
      },
      videos: []
    }
  },
  mounted () {
    // this.generateMediaWithExtensions(this.images)
  },
  computed: {
    ...mapGetters([
      'apiUrl'
    ]),
    cookieConsent () {
      return this.$store.state.cookieConsent
    }
  },
  created () {
    this.mediaData = this.generateMediaWithExtensions(this.images)

    this.foundVideos = this.mediaData.map((v, i) => {
      if (v.fileExtension === 'mp4' || v.fileExtension === 'mov') {
        return i
      }

      return null
    })
  },
  methods: {
    ...mapActions(['setCookieConsent']),
    move (e) {
      const allVideos = document.getElementsByTagName('video')
      const found = this.foundVideos.includes(e.index)

      for (const video of allVideos) {
        video.pause()
      }

      if (found) {
        this.$refs['htmlVideo' + e.index][0].play()
        console.debug(found)
      }
    },
    generateMediaWithExtensions (images) {
      if (images) {
        return images.map((obj) => {
          const fileName = obj
          const fileExtension = obj.split('.').pop()

          obj = {
            fileName: fileName,
            fileExtension: fileExtension
          }

          return obj
        })
      } else {
        return {}
      }
    },
    capturePoster (filename) {
      const videoCanvas = document.getElementById('.canvas')
      const video = document.createElement('video')

      video.src = this.apiUrl + '/uploads/' + filename
      videoCanvas
        .getContext('2d')
        .drawImage(video, 200, 200)

      return this.apiUrl + '/uploads/images/combiInduction%20main%20picture.png'
    }
  }
}
</script>

<style lang="scss">
.imageslider {
  img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    object-fit: contain;
    object-position: center;
    display: block;
    background: #fff;
  }

  li {
    display: flex;
    align-items: center;
  }
}

.splide {
  background: #fff;
  position: relative;
  height: 100%;

  &__video {
    video {
      background: #fff;
      object-fit: cover;
    }
  }
}

.imageslider {
  position: relative;
  width: 100%;
}

.splide__arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 20px 40px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  text-decoration: none;
  user-select: none;
  z-index: 1;
  background: none;

  svg {
    display: none;
  }
}

.splide__track {
  height: 100%;

  .splide__list {
    height: 100%;

    .splide__slide {
      height: 100%;
    }
  }
}

.splide__arrow--next {
  right: 0;

  &::after {
    content: $icon-chevron-right;
  }
}

.splide__arrow--prev {
  left: 0;

  &::after {
    content: $icon-chevron-left;
  }
}
</style>
