<template>
  <v-header class="no-print" />

  <div
    class="c-my-kitchen"
    ref="kitchen"
  >

    <div class="l-container">
      <div v-if="!kitchenInfo || kitchenInfo.data.category_products?.length < 1">
        <div class="c-my-kitchen__head">
          <div class="c-my-kitchen__section-head">
            <h1 class="c-my-kitchen__heading1">
              {{ translations[locale].text.your_kitchen_is_empty }}
            </h1>
          </div>
        </div>
      </div>

      <div v-if="kitchenInfo && kitchenInfo.data.category_products?.length >= 1">
        <div class="c-my-kitchen__head">
          <div class="c-my-kitchen__section-head">
            <h1 class="c-my-kitchen__heading1">
              {{
        translations[locale].text.here_are_all_the_appliances_that_you_want_in_your_next_dream_kithcen
      }}
            </h1>
          </div>

          <p
            style="margin-bottom:10px;"
            class="only-for-printing forhtml2canvas"
            v-if="slug"
          >
            <a
              class="text-brand-vibrant-petrol underline"
              v-bind:href="appBaseUrl + '/' + locale + '/my-kitchen/' + slug"
            >
              {{ appBaseUrl }}/{{ locale }}/my-kitchen/{{ slug }}
            </a>
          </p>

          <div
            class="flex flex-wrap gap-6"
            data-html2canvas-ignore
          >
            <div class="w-full md:w-64">
              <label
                for="kitchen-link"
                class="text-white text-xs"
              >
                {{ translations[locale].text.unique_link_for_your_kitchen }}
              </label>
              <div class="relative">
                <input
                  name="kitchen-link"
                  ref="kitchenLink"
                  class="c-input pr-6 no-print"
                  type="text"
                  :value="tinyUrl"
                  readonly
                />
                <v-icon
                  type="copy"
                  size="16"
                  class="absolute right-0 top-2 text-white cursor-pointer"
                  @click="copyLink"
                />
              </div>
            </div>

            <div class="flex flex-wrap items-center gap-6">
              <div class="w-full md:w-64">
                <label
                  for="kitchen-email-link"
                  ref="kitchenEmailLabel"
                  id="kitchenEmailLabel"
                  class="text-white text-xs"
                >
                  {{ translations[locale].text.send_your_kitchen_to_your_email }}
                </label>

                <input
                  name="kitchen-email-link"
                  ref="kitchenEmailLink"
                  class="c-input no-print"
                  type="text"
                />
              </div>

              <v-button
                variation="secondary"
                class="w-full md:w-auto no-print"
                :text="translations[locale].text.send"
                @click.prevent="sendEmail(locale)"
                data-html2canvas-ignore
              />

              <div class="c-my-kitchen__link-list no-print">
                <p
                  v-if="downloadLoading"
                  class="text-white downloadLoading"
                >
                  {{ translations[locale].text.downloading }}
                </p>
                <v-link
                  v-if="!downloadLoading"
                  :text="translations[locale].text.download_package_as_pdf"
                  url="#"
                  icon="download"
                  @click.prevent="download"
                />
                <!--                <v-link href="" @click="printPage" class="c-link c-link&#45;&#45;underline"-->
                <!--                        :text="translations[locale].text.print">-->
                <!--                  <v-icon placement="before" type="chevron-right" size="12"></v-icon>-->
                <!--                  <span>{{ translations[locale].text.print }}</span>-->
                <!--                </v-link>-->
              </div>
            </div>

            <div
              class="w-full flex md:justify-end"
              v-if="returnUrl"
              data-html2canvas-ignore
            >
              <a
                class="text-white inline-flex items-center bg-brand-vibrant-petrol hover:bg-brand-vibrant-petrol-hover px-4 py-2 rounded-sm transition font-bold w-full md:w-auto justify-center"
                :href="returnUrl"
              >
                <v-icon
                  type="chevron-left"
                  size="16"
                  class="mr-2"
                />
                {{ translations[locale].text.get_back_to_where_you_started }}
              </a>
            </div>
          </div>

        </div>

        <div
          class="kitchenbreak"
          v-for="info in kitchenInfo.data.category_products"
          :key="info"
        >
          <div
            v-if="info.settings && info.products_in_kitchen?.length >= 1"
            class="c-my-kitchen__section"
          >
            <div class="c-my-kitchen__section-head">
              <h1 class="text-3xl font-heading text-white">
                {{ info.settings.find(obj => obj.category_id === info.id)?.title }}
              </h1>

              <div class="c-my-kitchen__buttons">
                <div
                  class="no-print w-full md:w-auto"
                  data-html2canvas-ignore
                >
                  <v-button
                    v-if="info.settings.find(obj => obj.category_id === info.id)?.link_text"
                    variation="secondary"
                    :text="translations[locale].text.removeitem"
                    class="w-full"
                    @click="removeFromCart(info.products_in_kitchen, info.slug)"
                  />
                </div>
                <div
                  class="no-print w-full md:w-auto"
                  data-html2canvas-ignore
                >
                  <v-button
                    v-if="info.settings.find(obj => obj.category_id === info.id)?.link_text"
                    variation="secondary"
                    class="w-full"
                    :text="info.settings.find(obj => obj.category_id === info.id).link_text"
                    @click="$router.push({ path: `/${locale}${info.settings.find(obj => obj.category_id === info.id).link_url}` })"
                  />
                </div>
              </div>
            </div>

            <div class="c-my-kitchen__products">
              <div
                class="c-my-kitchen__product-bundle"
                v-if="info.products_in_kitchen.length > 1"
              >
                <div class="c-my-kitchen__product-bundle__products">
                  <v-child-product
                    :id="info.products_in_kitchen[0].id"
                    :addToKitchenButton="false"
                    :title="info.products_in_kitchen[0]?.title"
                    :description="info.products_in_kitchen[0].text"
                    :size="info.products_in_kitchen[0].size"
                    :sku="info.products_in_kitchen[0].sku"
                    :images="info.products_in_kitchen[0].media && info.products_in_kitchen[0].media.length ? Object.values(info.products_in_kitchen[0].media) : []"
                    :sizeOnImage="true"
                    :product="info.products_in_kitchen[0]"
                  />
                  <v-icon
                    class="c-my-kitchen__product-bundle-plus"
                    type="plus"
                    size="40"
                  />
                  <v-child-product
                    :id="info.products_in_kitchen[1].id"
                    :addToKitchenButton="false"
                    :title="info.products_in_kitchen[1]?.title"
                    :description="info.products_in_kitchen[1].text"
                    :size="info.products_in_kitchen[1].size"
                    :sku="info.products_in_kitchen[1].sku"
                    :images="info.products_in_kitchen[1].media && info.products_in_kitchen[1].media.length ? Object.values(info.products_in_kitchen[1].media) : []"
                    :sizeOnImage="true"
                    :product="info.products_in_kitchen[1]"
                  />
                </div>
              </div>
              <div
                class="c-my-kitchen__product"
                v-else
              >
                <div
                  class="c-my-kitchen__product-container"
                  :class="{
        'column': info.products_in_kitchen.length > 1
      }"
                  v-for="product in info.products_in_kitchen"
                  :key="product"
                >
                  <v-product
                    v-if="!product.child_products || !product.child_products.length"
                    class="c-my-kitchen__product"
                    :title="product?.title"
                    :description="product.text"
                    :size="product.size"
                    :sku="product.sku"
                    :images="product.media && product.media.length ? Object.values(product.media) : []"
                    :full="info.products_in_kitchen.length === 1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-white mb-16">
        <h2 class="text-white text-3.5xl lg:text-4.5xl font-heading mb-2">
          {{ pageInfo.data.title }}
        </h2>
        <div
          class="text-content"
          v-html="pageInfo.data.text"
        ></div>

        <a
          v-if="pageInfo.data.link_url && pageInfo.data.link_text"
          :href="pageInfo.data.link_url"
          data-html2canvas-ignore
          class="inline-flex bg-brand-vibrant-petrol rounded-sm hover:bg-brand-vibrant-petrol-hover transition font-bold px-6 py-2"
        >
          {{ pageInfo.data.link_text }}
        </a>

        <div
          v-if="retailerUrl || locale === 'en'"
          class="mt-10"
        >
          <h3 class="text-xl lg:text-2xl mb-6">
            {{ translations[locale].text.retailer_text }}
          </h3>

          <a
            :href="retailerUrl || ''"
            target="_blank"
            :class="!retailerUrl ? 'pointer-events-none' : 'underline hover:no-underline'"
          >
            {{ translations[locale].text.retailer_link_text }}
          </a>
        </div>

        <div class="flex justify-end mt-8">
          <v-button
            variation="primary"
            class="w-full md:w-auto no-print"
            :text="translations[locale].text.start_over"
            @click.prevent="clearStorage()"
            data-html2canvas-ignore
          />
        </div>
      </div>

      <div
        v-if="loading"
        class="lds-roller-wrapper"
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Button from '@/components/Button/Button.vue'
import Link from '@/components/Link/Link.vue'
import Product from '@/components/Product/Product.vue'
import ChildProduct from '@/components/Product/ChildProduct/ChildProduct.vue'
import Icon from '@/components/Icon/Icon.vue'
import axios from 'axios'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'
import { mapGetters, mapActions, mapMutations } from 'vuex'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}
export default {
  Name: 'My Kitchen',
  components: {
    'v-button': Button,
    'v-link': Link,
    'v-product': Product,
    'v-icon': Icon,
    'v-child-product': ChildProduct,
    'v-header': Header
  },
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      loading: false,
      products: null,
      singleProducts: [],
      translations,
      kitchenNotCreated: false,
      output: null,
      downloadLoading: false,
      tinyUrl: null,
      returnUrl: localStorage.getItem('returnUrl'),
      retailerUrl: null
    }
  },
  async created () {
    this.loadKitchenContent()
    await this.getTinyUrl()
    await this.loadMyKitchenBottomText()

    const curLang = document.querySelector('.c-language-switcher__button')?.dataset?.lang

    if (curLang !== 'en') {
      await this.loadMyKitchenBottomTextDifLang()
    }

    // this.products = this.kitchenInfo.data.settings.filter(obj => obj.products).map(obj => obj.products)
    // this.categories = this.kitchenInfo.data.categories
  },
  mounted () {
    if (this.returnUrl) {
      switch (this.returnUrl) {
        case 'https://www.siemens-home.bsh-group.com/se/bli-inspirerad/design/studioline':
          this.retailerUrl = this.locale === 'se' ? 'https://www.siemens-home.bsh-group.com/se/bli-inspirerad/design/studioline/partner' : null
          break
        case 'https://www.siemens-home.bsh-group.com/no/inspirasjon/design/studioline':
          this.retailerUrl = this.locale === 'no' ? 'https://www.siemens-home.bsh-group.com/no/inspirasjon/design/studioline/partnere' : null
          break
        case 'https://www.siemens-home.bsh-group.com/dk/inspiration/design/studioline':
          this.retailerUrl = this.locale === 'dk' ? 'https://www.siemens-home.bsh-group.com/dk/inspiration/design/studioline/koekkenbutikker' : null
          break
        case 'https://www.siemens-home.bsh-group.com/fi/etsi-inspiraatiota/muotoilu/studioline':
          this.retailerUrl = this.locale === 'fi' ? 'https://www.siemens-home.bsh-group.com/fi/etsi-inspiraatiota/muotoilu/studioline/jallenmyyja' : null
          break
        default:
          this.retailerUrl = null
      }
    }
  },
  computed: {
    ...mapGetters([
      'kitchenInfo',
      'kitchenSlug',
      'apiUrl',
      'appBaseUrl',
      'apiHeader',
      'kitchenId',
      'token',
      'addedProducts',
      'returnUrl',
      'pageInfo'
    ])
  },
  methods: {
    ...mapActions(['loadKitchen', 'loadMyKitchenBottomText', 'loadMyKitchenBottomTextDifLang']),
    ...mapMutations([
      'clearChoices',
      'clearDesignChoices',
      'clearAddedProduct',
      'clearSizeRestrictions',
      'clearAddOnFeatures',
      'removeProductFromKitchen',
      'updateKitchen',
      'updateDoneCategories'
    ]),
    printPage () {
      window.print()
    },
    async getTinyUrl () {
      if (this.slug) {
        this.loading = true

        await axios.post(`https://api.tinyurl.com/create?api_token=${this.$store.state.tinyurlApi}`,
          {
            url: `${this.appBaseUrl}/${this.locale}/my-kitchen/${this.slug}`
          },
          {
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          if (response?.data?.data?.tiny_url) {
            this.tinyUrl = response.data.data.tiny_url
          } else {
            this.tinyUrl = `${this.appBaseUrl}/${this.locale}/my-kitchen/${this.slug}`
          }
        }).catch(() => {
          this.tinyUrl = `${this.appBaseUrl}/${this.locale}/my-kitchen/${this.slug}`
        })

        this.loading = false
      }
    },
    loadKitchenContent () {
      this.loading = true
      this.slug = this.$route.params.kitchenSlug ? this.$route.params.kitchenSlug : this.kitchenSlug

      if (this.slug === null) {
        this.kitchenNotCreated = true
      } else {
        this.loadKitchen(this.slug)
      }

      this.clearChoices()
      this.clearDesignChoices()
      this.clearSizeRestrictions()
      this.clearAddedProduct()
      this.clearAddOnFeatures()
      this.loading = false
    },
    async sendEmail (locale) {
      const emailaddress = this.$refs.kitchenEmailLink.value
      const currentLang = locale
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailaddress)) {
        await axios.get(`${this.apiUrl}/api/kitchen/email/${this.slug}?lang=${currentLang}&email=${emailaddress}&shorturl=${this.tinyUrl}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }
        ).then((response) => {
          this.$refs.kitchenEmailLink.value = ''
          document.getElementById('kitchenEmailLabel').innerHTML = translations[currentLang].text.pdf_successfully_sent_to_your_email
          document.getElementById('kitchenEmailLabel').classList.add('label--notification')
        })
      } else {
        document.getElementById('kitchenEmailLabel').innerHTML = translations[currentLang].text.valid_email_required
        document.getElementById('kitchenEmailLabel').classList.add('label--notification')
      }
    },
    copyLink () {
      const el = this.$refs.kitchenLink
      el.select()
      el.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(el.value)
    },
    async removeFromCart (products, slug) {
      this.loading = true
      let productIds = [products[0].id]
      if (slug === 'dishwasher') {
        const idDishWasherArr = []
        idDishWasherArr.push(products[0].id)
        if (this.addedProducts['dishwasher-extras']?.length > 0) {
          for (let i = 0; i < this.addedProducts['dishwasher-extras'].length; i++) {
            idDishWasherArr.push(this.addedProducts['dishwasher-extras'][i].id)
          }
        }
        productIds = idDishWasherArr
      }
      if (products.length > 1) {
        const idArr = []
        for (let i = 0; i < products.length; i++) {
          idArr.push(products[i].id)
        }
        productIds = idArr
      }

      await axios.post(`${this.apiUrl}/api/kitchen/remove`,
        {
          id: this.kitchenId,
          product_id: productIds,
          lang: this.$store.state.locale,
          slug: slug
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        this.loadKitchen(this.slug)
        this.$store.commit('updateDoneCategories', {
          response,
          productIds,
          slug
        })
        this.loading = false
      })
    },
    async download () {
      const encodedUrl = encodeURIComponent(this.tinyUrl)
      this.downloadLoading = true
      window.open(`${this.apiUrl}/api/file/generate/${this.slug}?lang=${localStorage.getItem('locale')}&shorturl=${encodedUrl}`)
      this.downloadLoading = false
    },
    clearStorage () {
      localStorage.removeItem('vuex')
      window.location.href = `/${this.$route.params.lang}/`
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.c-my-kitchen {
  .downloadLoading {
    opacity: 0.6;

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
      animation: ellipsis steps(4, end) 1200ms infinite;
      content: "\2026";
      /* ascii code for the ellipsis character */
      width: 0;
    }
  }

  &__progressbar {
    margin-top: 32px;
    margin-bottom: 84px;
  }

  &__heading1 {
    @include t2;
    color: #fff;
  }

  &__buttons {
    display: flex;
    gap: 12px;

    @include mq($screen-m, max) {
      flex-wrap: wrap;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__head {
    margin-bottom: 120px;

    @include mq($screen-l, min) {
      /* max-width: 60%; */
    }
  }

  &__save {
    display: flex;
    align-items: center;
  }

  &__save-link {
    margin-left: 32px;
  }

  .email--wrapper {
    margin-left: 28px;

    @include mq($screen-m, max) {
      margin-left: 0px;
    }
  }

  .label--notification {
    color: $teal-light;
  }

  &__link-container-wrapper {
    @include mq($screen-m, max) {
      width: 100%;
    }

    button {
      @include mq($screen-m, max) {
        width: 100%;
      }
    }
  }

  &__link-container {
    display: flex;
    align-items: center;
  }

  &__link-list {
    a {
      margin-right: 16px;
      display: inline-block;

      @include mq($screen-s, max) {
        display: inherit;
        margin-top: 16px;
      }
    }
  }

  &__link-label {
    color: #fff;
    margin-bottom: 4px;
    line-height: 24px;
  }

  &__link {
    padding: 10px;
    width: 300px;
    @include s2;
  }

  &__products {
    display: flex;
    width: 100%;
    gap: 40px;
  }

  &__product {
    &-bundle {
      border: 4px solid $teal-light;
      padding: 40px;
      margin-bottom: 40px;
      position: relative;

      @include mq($screen-s, max) {
        padding: 20px;
      }

      &::before {
        content: "Bundle";
        position: absolute;
        top: -15px;
        left: 40px;
        background-color: $teal-light;
        border-left: 4px solid $deep-blue;
        border-right: 4px solid $deep-blue;
        padding-left: 16px;
        padding-right: 16px;
        color: #fff;
        line-height: 28px;
      }

      &:lang(se) {
        &::before {
          content: "Paket";
        }
      }

      &:lang(en) {
        &::before {
          content: "Bundle";
        }
      }
    }

    &-bundle {
      width: 100%;

      &__products {
        display: flex;
        align-items: center;
        align-items: stretch;

        @include mq($screen-m, max) {
          flex-direction: column;
        }

        .c-child-product {
          flex: 1;

          @include mq($screen-m, max) {
            max-width: 100%;
          }
        }
      }
    }

    &-bundle-plus {
      color: $teal-light;
      margin-left: 20px;
      margin-right: 20px;
      min-width: 40px;
      align-self: center;

      @include mq($screen-m, max) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    width: 100%;
  }

  &__bundle {
    border: 4px solid $teal-light;
    padding: 40px;
    position: relative;

    &::before {
      content: "Bundle";
      position: absolute;
      top: -15px;
      left: 40px;
      background-color: $teal-light;
      border-left: 4px solid $stone-1;
      border-right: 4px solid $stone-1;
      padding-left: 16px;
      padding-right: 16px;
      color: #fff;
      line-height: 28px;
    }
  }

  &__bundle-products {
    display: flex;
    align-items: center;
    align-items: stretch;
  }

  &__bundle-plus {
    color: $teal-light;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 40px;
    align-self: center;
  }

  &__section,
  &__textmedia {
    margin-bottom: 60px;
  }

  &__section-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include mq($screen-m, max) {
      margin-bottom: 0;
    }
  }

  &__back {
    border-top: 1px solid $stone-dark;
    padding-top: 42px;
    margin-bottom: 122px;
  }

  @include mq($screen-m, max) {
    &__head {
      margin-bottom: 40px;
    }

    &__product:not(:last-child) {
      margin-right: 0;
      margin-bottom: 40px;
    }

    &__bundle-products,
    &__products {
      flex-direction: column;
    }

    &__bundle-plus {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__textmedia,
    &__section {
      margin-bottom: 40px;
    }

    &__section-head {
      flex-direction: column;
    }

    &__heading1 {
      margin-bottom: 20px;
    }

    &__save {
      flex-direction: column;
      align-items: flex-start;
    }

    &__link-container {
      margin-bottom: 16px;
      flex-direction: column;
      position: relative;
      width: 100%;
    }

    &__save-link {
      margin-left: 0;
    }

    &__link {
      padding: 10px;
      width: 100%;
    }
  }

  @include mq($screen-s, max) {
    &__product:not(:last-child) {
      margin-bottom: 20px;
    }

    &__bundle {
      padding: 20px;
    }
  }
}

.only-for-printing {
  margin-top: 10px;
  display: none;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }

  .c-my-kitchen__link {
    width: 100%;
  }

  .only-for-printing {
    display: block;
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
