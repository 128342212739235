<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div v-if="!reminderSent" class="modal-container">
          <button class="modal__close" @click="$emit('close')">✕</button>

          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <label class="text-brand-deep-blue-2 text-sm">{{ translations[locale].text.insert_your_email }}</label>
              <input name="c-reminder__email" class="c-reminder__email-input" type="email" v-model="emailInput"/>

              <div v-if="errors.length" class="text-brand-error text-sm mt-2">
                <ul>
                  <li v-for="error in errors" :key='error'>{{ error }}</li>
                </ul>
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <v-button variation="primary" class="c-reminder__button w-full" @click="checkForm()">
                {{ translations[locale].text.send_reminder }}
              </v-button>
            </slot>
          </div>
        </div>

        <div v-else class="modal-container">
          <button class="modal__close" @click="$emit('close')">✕</button>

          <div class="modal-body">
            <slot name="body">
              {{ translations[locale].text.reminder_is_sent_to_your_email }}
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <v-button variation="primary" class="c-reminder__button w-full" @click="$emit('close')">
                {{ translations[locale].text.close }}
              </v-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button/Button.vue'
import { ENGLISH_TRANSLATIONS } from '@/translations/en'
import { SWEDISH_TRANSLATIONS } from '@/translations/se'
import { DANISH_TRANSLATIONS } from '@/translations/dk'
import { NORWEGIAN_TRANSLATIONS } from '@/translations/no'
import { FINNISH_TRANSLATIONS } from '@/translations/fi'

const translations = {
  en: ENGLISH_TRANSLATIONS,
  se: SWEDISH_TRANSLATIONS,
  dk: DANISH_TRANSLATIONS,
  no: NORWEGIAN_TRANSLATIONS,
  fi: FINNISH_TRANSLATIONS
}

export default {
  name: 'Modal',
  data () {
    return {
      route: this.$route.path,
      locale: this.$route.params.lang,
      showModal: false,
      reminderSent: false,
      emailInput: '',
      errors: [],
      translations
    }
  },
  components: {
    'v-button': Button
  },
  methods: {
    sendReminder: function () {
      this.reminderSent = true
    },
    checkForm: function (e) {
      this.errors = []
      if (!this.emailInput) {
        this.errors.push(this.translations[this.locale].text.email_required)
      } else if (!this.validEmail(this.emailInput)) {
        this.errors.push(this.translations[this.locale].text.valid_email_required)
      } else {
        this.sendReminder()
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  color: #000;
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-errors {
  color: #E0173B;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  @apply my-5;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

.c-reminder__email-input {
  @apply transition pb-2 w-full;

  min-width: 250px;
  border: none;
  border-bottom: 2px solid #000;
  display: block;

  &:focus {
    outline: none;
    border-color: $teal-light;
  }
}

.modal {
  &-container {
    position: relative;
  }

  &__close {
    background: none;
    position: absolute;
    top: 5px;
    right: 24px;
    padding: 5px;
    text-align: center;
    vertical-align: center;
    transition: all 0.3s;
    font-size: 24px;
    color: #000;

    &:hover {
      color: #000;
    }
  }
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
</style>
